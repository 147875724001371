import { useLoadScript } from '@react-google-maps/api'
import { useMemo } from 'react'

type Libraries = (
    | 'drawing'
    | 'geometry'
    | 'localContext'
    | 'places'
    | 'visualization'
)[]

export const config = {
    id: `google-maps-script`,
    googleMapsApiKey:
        process.env.NEXT_PUBLIC_MAPS_KEY ||
        'AIzaSyAteKyqKYjwNCx6wME--n-ScSCmrwFjLrQ',
    version: '3.exp',
    libraries: ['places', 'geometry', 'visualization'] as Libraries,
    language: 'nl',
    region: 'NL',
}

let autocompleteService: google.maps.places.AutocompleteService | null = null
export const useAutocompleteService = () => {
    const { isLoaded } = useLoadScript(config)

    return useMemo(() => {
        if (autocompleteService) return autocompleteService
        if (!isLoaded) return null
        autocompleteService =
            new window.google.maps.places.AutocompleteService()
        return autocompleteService
    }, [isLoaded])
}

let sessionToken: google.maps.places.AutocompleteSessionToken | null = null
export const useSessionToken = () => {
    const { isLoaded } = useLoadScript(config)

    return useMemo(() => {
        if (sessionToken) return sessionToken
        if (!isLoaded) return null
        sessionToken = new window.google.maps.places.AutocompleteSessionToken()
        return sessionToken
    }, [isLoaded])
}

let placeService: google.maps.places.PlacesService
export const usePlaceService = () => {
    const { isLoaded } = useLoadScript(config)

    return useMemo(() => {
        if (placeService) return placeService
        if (!isLoaded) return null
        placeService = new window.google.maps.places.PlacesService(
            document.createElement('div')
        )
        return placeService
    }, [isLoaded])
}
